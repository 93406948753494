import { Searchable } from './searchable.model';
import { User } from './user.interface';

/* eslint-disable @typescript-eslint/naming-convention */
// Note camelCase: DB attributes
export class Auth {
    OrganizationId?: string;
    accessToken?: string;
    RefreshToken?: string;
    Email?: string;
    customerId?: string;
    contactId?: string;
    OrganizationType?: string;
    Name?: string;
}

// TODO: Remove this after including Name as part of Auth
export interface UserLogin extends Searchable {
    accessToken: string;
    RefreshToken: string;
    OrganizationType: string;
    name?: string;
}

export type AuthData = Auth & User;
