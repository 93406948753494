import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidationErrorResult } from '../models/validators.model';

/**
 * Checks for basic phone validation.
 * Only numbers, + () and space and trailing letters for Extensions are allowed
 * @param ctrl
 * @returns Either null or a translatable token if validation fails in ValidationErrorResult
 */
const phoneValidatorInternational: ValidatorFn = (ctrl: AbstractControl): ValidationErrorResult | null => {
    const basePhoneRgx = /^\+?[1-9]\d{1,14}$/;
    const value = ctrl.value;

    if (typeof value !== 'string' || value.trim() === '') {
        return null;
    }

    return basePhoneRgx.test(ctrl.value.trim())
        ? null
        : {
              phone: {
                  message: 'FormMessages.InvalidPhone',
                  param: {
                      value: ctrl.value
                  }
              }
          };
};

/** -----------------------------
 * Validator Exports
 ---------------------------------*/
export const AppFormValidators = {
    phoneValidatorInternational
};
