<div class="intl-tel-input-container">
    <ngx-intl-tel-input
        [cssClass]="cssClass"
        [preferredCountries]="preferredCountries"
        [enableAutoCountrySelect]="enableAutoCountrySelect"
        [enablePlaceholder]="enablePlaceholder"
        [searchCountryFlag]="searchCountryFlag"
        [selectFirstCountry]="selectFirstCountry"
        [maxLength]="maxLength"
        [phoneValidation]="phoneValidation"
        [separateDialCode]="separateDialCode"
        [formControl]="phone"
        [ngClass]="styleInput"></ngx-intl-tel-input>
    <div class="alert" *ngIf="phone?.touched && phone?.hasError('required')">
        <span apxIcon icon="info"></span>
        {{ 'PhoneRequired' | translate }}
    </div>
    <div class="alert" *ngIf="phone?.touched && phone?.invalid && phone?.value">
        <span apxIcon icon="info"></span>
        {{ 'InvalidNumber' | translate }}
    </div>
</div>
