<div class="form-container por-signup">
    <h2 class="theme-header">{{ 'Register/Sign Up' | translate }}</h2>
    <form [formGroup]="signupForm" (ngSubmit)="signup()" novalidate>
        <apx-form-field
            label="Name"
            class="apx-input-container d-inline-flex mb-sm"
            [error]="name.invalid && (name.dirty || name.touched) ? 
            name.errors?.['required'] ? ('PleaseEnterYourName' | translate) : 
            name.errors?.['maxlength'] ? ('max_128_chars_permitted' | translate):'' 
            : '' ">
            <por-svg-icon name="user"></por-svg-icon>
            <apx-input-group>
                <input
                    porAddFeature
                    featureName="signup-name-input"
                    apxInput
                    type="text"
                    formControlName="name"
                    autocomplete="on"
                    name="name"
                    placeholder="{{ 'EnterYourName' | translate }}"
                    class="theme-input" />
            </apx-input-group>
        </apx-form-field>
        <apx-form-field
            label="Company Name"
            class="apx-input-container d-inline-flex mb-sm"
            [error]="companyName.invalid && (companyName.dirty || companyName.touched) ? 
            companyName.errors?.['required'] ? ('CompanyNameRequired' | translate) : 
            companyName.errors?.['maxlength'] ? ('max_128_chars_permitted' | translate):'' 
            : '' ">
            <por-svg-icon name="company"></por-svg-icon>
            <apx-input-group>
                <input
                    porAddFeature
                    featureName="signup-company-name-input"
                    apxInput
                    type="text"
                    formControlName="companyName"
                    autocomplete="on"
                    name="companyName"
                    placeholder="{{ 'EnterYourCompanyName' | translate }}"
                    class="theme-input" />
            </apx-input-group>
        </apx-form-field>
        <div class="form-field">
            <label for="">{{ 'Phone Number' | translate }}</label>
            <por-intl-tel-input
                [cssClass]="'country-code'"
                [preferredCountries]="['us']"
                [enableAutoCountrySelect]="false"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [selectFirstCountry]="true"
                [maxLength]="15"
                [phoneValidation]="true"
                [separateDialCode]="true"
                formControlName="phoneNumber"
                porAddFeature
                featureName="signup-phone-number-input"
                [styleInput]="'phone-input-style'"></por-intl-tel-input>
        </div>
        <apx-form-field
            label="Email"
            class="apx-input-container d-inline-flex mb-sm"
            [error]="username.invalid && (username.dirty || username.touched) ? 
            username.errors?.['required'] ? ('Please enter username.' | translate) : 
            username.errors?.['email'] ? ('Please enter valid email.' | translate) : 
            username.errors?.['maxlength'] ? ('max_128_chars_permitted' | translate):'' 
            : '' ">
            <apx-input-group>
                <span apxPrefix apxIcon icon="email"></span>
                <input
                    porAddFeature
                    featureName="signup-email-input"
                    apxInput
                    formControlName="username"
                    type="email"
                    autocomplete="on"
                    name="username"
                    placeholder="{{ 'Enter your email' | translate }}"
                    class="theme-input" />
            </apx-input-group>
        </apx-form-field>
        <apx-form-field
            label="Password"
            class="apx-input-container d-inline-flex mb-sm"
            [error]="password.invalid && (password.dirty || password.touched) ? 
            password.errors?.['required'] ? ('Please enter password.' | translate) : 
            password.errors?.['minlength'] ? ('Password minimum length should be 6.' | translate) : 
            password.errors?.['maxlength'] ? ('max_50_chars_permitted' | translate):'' 
            : '' ">
            <apx-input-group>
                <span apxPrefix apxIcon icon="lock"></span>
                <input
                    porAddFeature
                    featureName="signup-password-input"
                    apxInput
                    formControlName="password"
                    type="password"
                    name="password"
                    autocomplete="on"
                    placeholder="{{ 'Enter your password' | translate }}"
                    class="theme-input"
                    maxlength="50" />
            </apx-input-group>
        </apx-form-field>
        <button
            porAddFeature
            featureName="signup-signup-button"
            class="btn-primary btn-full-width theme-button"
            type="submit"
            apxButton
            variant="primary"
            [disabled]="signupForm.pristine || signupForm.invalid || isDisableButton">
            {{ 'Sign Up' | translate }}
        </button>
    </form>
    <p class="left-align theme-paragraph">
        {{ 'Already have an account?' | translate }}
        <a porAddFeature featureName="signup-signin-button" href="#" (click)="setTemplate('signin')" class="theme-anchor">{{ 'Sign in here' | translate }}</a>
    </p>
</div>
