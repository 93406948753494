/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, concatMap, filter, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { Auth, UserLogin, AuthData } from '../models/auth.model';
import { ConsumerLoginApiService } from './consumer-login-api.service';
import { ApiRequest, User } from '../models';
import { isEmpty } from 'lodash';
import { RedirectionUsers } from '../models/redirection-users';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private readonly apiService: ConsumerLoginApiService) {}
    private readonly authData = new BehaviorSubject<AuthData | null>(null);
    private readonly authError = new BehaviorSubject<HttpErrorResponse | null>(null);
    authDataError$ = this.authError.asObservable();

    authData$ = this.authData.asObservable().pipe(
        filter(x => !!x?.OrganizationId), // Filter out null values
        switchMap(x =>
            this.loginUser(x as User, x?.OrganizationId ?? '0').pipe(
                catchError(error => {
                    this.authError.next(error);
                    return of(null);
                })
            )
        ),
        tap(result => {
            if (result) {
                const { authData } = result;
                this.apiService.setAuthData(authData as Auth);
            }
        }),
        map(result => result?.authData || null),
        catchError(error => {
            this.authError.next(error);
            return throwError(() => error);
        }) // Catch error
    );

    get getAuthData$() {
        return this.authData$;
    }

    get getAuthDataError$() {
        return this.authDataError$;
    }

    resetAuthError() {
        this.authError.next(null);
    }

    getValue(): AuthData | null {
        return this.authData.getValue();
    }

    initiateLogin(orgId: string, loginData?: User | null) {
        // DB properties
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const data: AuthData = {
            ...loginData,
            Email: loginData?.Email ?? '',
            StoreName: loginData?.StoreName ?? '',
            Password: loginData?.Password ?? '',
            code: loginData?.code ?? '',
            Name: loginData?.Name ?? '',
            CompanyName: loginData?.CompanyName ?? '',
            PhoneNumber: loginData?.PhoneNumber ?? '',
            OrganizationId: orgId
        };
        this.authData.next(data);
    }

    /**
     * Authenticates a user and processes user accounts if required.
     * @param loginData - User credentials.
     * @param orgId - Organization ID.
     * @returns Observable with authData and optional userAccounts.
     */

    private loginUser(loginData: User, orgId: string): Observable<{ authData: Auth; userAccounts?: RedirectionUsers[] }> {
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Note camelCase: DB model
         */
        const body: ApiRequest = {
            OrganizationId: orgId,
            Email: loginData.Email,
            Password: loginData.Password
        };
        return this.apiService.getByPost<UserLogin>('auth', 'login', body).pipe(
            filter(data => !isEmpty(data) && !Array.isArray(data)),
            concatMap(data => {
                const response = data as UserLogin; // Explicitly typed
                const authData: Auth = {
                    OrganizationId: orgId,
                    Email: loginData.Email,
                    accessToken: response.accessToken,
                    RefreshToken: response.RefreshToken,
                    OrganizationType: response.OrganizationType,
                    Name: response.name
                };
                return of({ authData });
            }),
            catchError(error => {
                return throwError(() => error);
            })
        );
    }
}
