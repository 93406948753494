import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService, ApiSendParams } from '../abstract/api.service';
import { ApiQuery } from '../models/api-query.model';
import { ApiResult, ApiResultMessageType } from '@por/shared/core';
import { Searchable } from '../models/searchable.model';
import { ApiRequest, Auth } from '../models';
import { FindHttpResponse } from '../models/api-result.model';
import { ApiPaging } from '../models/api-paging.model';
import { AuthService } from './auth.service';

/**
 * Specialized App-wide service
 * Provided in the App Module
 * Override provider as necessary to provide a specialized implementation
 * i.e. { provide: ApiService, useClass: OtherApiService }
 * */
@Injectable({ providedIn: 'root' })
export class ConsumerLoginApiService extends ApiService {
    defaultPageSize = 25;
    apiRoute = '';
    cpApiRoute = '';
    authData: Auth = {};

    constructor(protected http: HttpClient) {
        super();
    }

    requestAuthHeaders() {
        let headers = {};
        const authData = this.authData;

        if (authData) {
            const accessToken = authData.accessToken;
            if (accessToken) {
                headers = {
                    /* eslint-disable @typescript-eslint/naming-convention */
                    /**
                     * Note camelCase: DB Modal
                     */
                    Authorization: accessToken
                };
            }
        }

        return headers;
    }

    setAuthData(authDataObj: Auth): void {
        this.authData = authDataObj;
    }

    setApiRoute(apiUrl: string, cpApiUrl: string) {
        this.apiRoute = apiUrl;
        this.cpApiRoute = cpApiUrl;
    }

    // Requesting multiple entities:
    get<T extends Searchable>(controller: string, apiQuery: ApiQuery): Observable<T[]> {
        const url: string = this.apiRoute + '/' + controller;
        return this.find<T>(url, apiQuery).pipe(map((result: ApiResult<T>) => (result && result.Records ? result.Records : [])));
    }

    getData<T extends Searchable>(controller: string, apiQuery: ApiQuery): Observable<T[]> {
        const url: string = this.apiRoute + '/' + controller;
        return this.find<T>(url, apiQuery).pipe(map((result: ApiResult<T>) => (result && result.Records ? result.Records : [])));
    }

    // Requesting a single entity:
    getById<T extends Searchable>(controller: string, id: string, apiQuery: ApiQuery): Observable<T | null> {
        const url: string = this.apiRoute + '/' + controller + '/' + id;
        return this.find<T>(url, apiQuery).pipe(map((result: ApiResult<T>) => (result && result.Records && result.Records.length ? result.Records[0] : null)));
    }

    // Requesting a list of items by post endpoint using array of parameters i.e. menu items //TODO Review if necessary
    getByPost<T extends Searchable>(controller: string, method: string, body: ApiRequest): Observable<T[] | T> {
        return this.send<ApiResult<T>>({
            verb: 'POST',
            controller,
            method,
            params: null,
            body
        }).pipe(map((result: ApiResult<T>) => (result && result.Records && result.Records ? result.Records : [])));
    }

    search<T extends Searchable>(controller: string, apiQuery: ApiQuery): Observable<ApiResult<T>> {
        const url: string = this.apiRoute + '/' + controller;
        return this.find<T>(url, apiQuery);
    }

    add<T>(controller: string, method: string, entity: ApiRequest): Observable<T> {
        return this.send({
            verb: 'POST',
            controller,
            method,
            params: null,
            body: entity
        });
    }

    save<T extends Searchable>(controller: string, entity: ApiRequest): Observable<T> {
        return this.send({
            verb: 'PUT',
            controller,
            method: null,
            params: null,
            body: entity
        });
    }

    /**
     * Used for getting general resources
     * @param url
     * @param params
     */
    protected find<T>(url: string, params: ApiQuery): Observable<ApiResult<T>> {
        // Use JSON content:
        params = params || {};

        /* eslint-disable @typescript-eslint/naming-convention */
        /*
         * GAPI follows
         */
        const headers = new HttpHeaders({
            'X-Filter': JSON.stringify(params.filters || ''),
            'X-Columns': JSON.stringify(params.columns || ''),
            'X-Paging': JSON.stringify(params.paging || ''),
            'X-Sort': JSON.stringify(params.sort || ''),
            'X-Options': JSON.stringify(params.options || '')
        });

        return this.http.get(url, { headers, observe: 'response' }).pipe(
            map((res: HttpResponse<FindHttpResponse>) => {
                let range = ['1', '1'];

                if (res.headers.has('Content-Range')) {
                    const r: string = res.headers.get('Content-Range') as unknown as string;
                    range = r.replace('page ', '').split('/');
                }
                // TODOMM review pagination including total count
                const paging: ApiPaging = params.paging || {};
                let totalCount = 1;

                if (res.headers.has('X-Total-Count')) {
                    const count: number = res.headers.get('X-Total-Count') as unknown as number;
                    totalCount = +count;
                } else if (res?.body?.paging?.totalCount) {
                    totalCount = res?.body?.paging?.totalCount;
                }

                return {
                    ...res.body,
                    paging: {
                        page: +range[0] || 1,
                        pageSize: paging.pageSize || this.defaultPageSize,
                        totalCount
                    },
                    Message: {
                        Type: ApiResultMessageType.Success
                    }
                };
            }),
            catchError(error => {
                throw new Error(error.message);
            })
        );
    }

    /**
     * Used for saving/updating resources
     * @param verb
     * @param controller
     * @param method
     * @param params
     * @param body
     * @param headers
     */
    send<T>(args: ApiSendParams): Observable<T> {
        const { verb, controller, method, params, apiRoute, headers, queryParams } = args;
        let { body } = args;
        try {
            const urlRoute: string = apiRoute ? apiRoute : this.apiRoute;
            // Create URL with route and query string parameters:
            let url: string = urlRoute + '/' + controller + (method ? '/' + method : '');

            if (params && params.size > 0) {
                params.forEach((value: unknown) => {
                    if (value !== undefined && value !== null) {
                        url += '/' + (value instanceof Date ? encodeURIComponent(value.toISOString()) : encodeURIComponent(value.toString()));
                    }
                });
            }

            // Wrap strings in single or double quotes:
            if (body && typeof body === 'string') {
                body = body.indexOf('"') >= 0 ? "'" + body + "'" : '"' + body + '"';
            }

            if (queryParams && Object.keys(queryParams).length > 0) {
                // Convert the queryParams object to an array of key-value pairs for URLSearchParams
                const searchParams = new URLSearchParams();
                Object.entries(queryParams).forEach(([key, value]) => {
                    // Convert all values to string (URLSearchParams requires string values)
                    searchParams.append(key, value.toString());
                });
                // Append the query string to the URL
                url += (url.indexOf('?') === -1 ? '?' : '&') + searchParams.toString();
            }
            switch (verb) {
                case 'GET':
                    return this.http.get(url, { headers }).pipe(map(res => res as T));
                case 'PUT':
                    return this.http.put<T>(url, body, { headers }) as Observable<T>;
                case 'PATCH':
                    return this.http.patch<T>(url, body, { headers }) as Observable<T>;
                case 'DELETE':
                    return this.http.delete<T>(url, { headers });
                case 'DELETE_WITH_BODY':
                    return this.http.request<T>('DELETE', url, { body }) as Observable<T>;
                case 'POST':
                    return this.http.post<T>(url, body, { headers }).pipe(map(res => res as T));
                default:
                    throw new Error('Invalid verb provided.');
            }
        } catch (ex) {
            throw new Error(ex as string);
        }
    }
}
