import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CONSUMER_LOGIN_APP_CONFIG, ConsumerLoginConfig } from '../../models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
    selector: 'por-svg-icon',
    template: `
        <span [innerHTML]="svgIcon"></span>
    `,
    styleUrls: ['./icon-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SvgIconComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public name?: string;

    public svgIcon!: SafeHtml;

    private readonly nameChange$ = new BehaviorSubject<string | undefined>(undefined);
    private subscription: Subscription = new Subscription();

    constructor(
        private readonly httpClient: HttpClient,
        private readonly sanitizer: DomSanitizer,
        private readonly cdr: ChangeDetectorRef,
        @Inject(CONSUMER_LOGIN_APP_CONFIG) private readonly appConfig: ConsumerLoginConfig
    ) {}

    ngOnInit(): void {
        this.subscription = this.nameChange$
            .pipe(
                distinctUntilChanged(), // Avoid duplicate emissions
                switchMap(name => {
                    if (!name) {
                        this.svgIcon = '';
                        this.cdr.detectChanges();
                        return [];
                    }
                    return this.httpClient.get(`${this.appConfig?.uiUrl}/assets/images/${name}.svg`, { responseType: 'text' });
                })
            )
            .subscribe((value: string) => {
                if (value) {
                    this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(value);
                    this.cdr.detectChanges();
                }
            });
    }

    ngOnChanges(): void {
        this.nameChange$.next(this.name);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
