import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntlTelInputComponent } from './components/intl-tel-input/intl-tel-input.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UIMissingTranslationHandler, UITranslateLoader } from '@por/shared/ui/translations';
import { Language } from '@por/shared/core';
import * as EnUSTranslations from '../lib/assets/en-US.json';
import * as EnGBTranslations from '../lib/assets/en-GB.json';
import { createCustomElement } from '@angular/elements';
import { ApxIconModule } from '@por/apex';
import { ApxIconCheck } from '@por/apex/icons';
@NgModule({
    imports: [
        CommonModule,
        NgxIntlTelInputModule,
        ReactiveFormsModule,
        FormsModule,
        ApxIconModule.forRoot([ApxIconCheck]),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: () => {
                    return new UITranslateLoader({ [Language.EN_US]: EnUSTranslations, [Language.EN_GB]: EnGBTranslations });
                }
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: UIMissingTranslationHandler },
            defaultLanguage: Language.EN_US
        })
    ],
    declarations: [IntlTelInputComponent],
    exports: [IntlTelInputComponent]
})
export class EcomSharedModule implements DoBootstrap {
    constructor(private readonly injector: Injector) {}
    ngDoBootstrap(): void {
        const phone = createCustomElement(IntlTelInputComponent, {
            injector: this.injector
        });
        customElements.define('por-intl-tel-input', phone);
    }
}
