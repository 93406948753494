import { inputDefaults } from '../../input-config/input.defaults';
import { ConsumerLoginConfig, Styling } from '../models/consumer-login-config';
import { Injectable } from '@angular/core';

type PorColors = Partial<{ [Key: string]: string }>;

@Injectable({
    providedIn: 'root'
})
export class SiteSettingService {
    setStyling(styling: Styling | undefined, id: string) {
        const header: string[] = styling?.header as [];
        const paragraph: string[] = styling?.paragraph as [];
        const input: string[] = styling?.input as [];
        const button: string[] = styling?.button as [];
        const anchor: string[] = styling?.anchor as [];

        // ToDo: Replace use if document directly to Angular Renderer2
        const style = document.createElement('style');
        style.id = id;

        if (header) {
            /* Header Style */
            let headerHtml = `.${id} .theme-header{ `;
            for (const key in header) {
                /**
                 * Added if condition to filter unwanted properties from the prototype
                 */
                if (Object.prototype.hasOwnProperty.call(header, key)) {
                    headerHtml += `${key}: ${header[key]};`;
                }
            }
            headerHtml += '}';
            style.innerHTML += headerHtml;
        }

        if (paragraph) {
            /* Paragraph Style */
            let paraHtml = `.${id} .theme-paragraph{ `;
            for (const key in paragraph) {
                /**
                 * Added if condition to filter unwanted properties from the prototype
                 */
                if (Object.prototype.hasOwnProperty.call(paragraph, key)) {
                    paraHtml += `${key}: ${paragraph[key]};`;
                }
            }
            paraHtml += '}';
            style.innerHTML += paraHtml;
        }

        if (button) {
            /* Button style */
            const insideArray: string[] = [];
            let buttonHtml = `.${id} .theme-button{ `;
            for (const key in button) {
                /**
                 * Added if condition to filter unwanted properties from the prototype
                 */
                if (Object.prototype.hasOwnProperty.call(button, key)) {
                    /**
                     * The body of a for-in should be wrapped in an if statement to filter unwanted properties from the prototype
                     */
                    if (typeof button[key] === 'string') {
                        buttonHtml += `${key}: ${button[key]};`;
                    }
                    if (typeof button[key] === 'object' && key === 'inside') {
                        let insidehtml = '.theme-button span, .theme-button p{';
                        for (const buttonKey in button[key] as unknown as []) {
                            /**
                             * Added if condition to filter unwanted properties from the prototype
                             */
                            if (Object.prototype.hasOwnProperty.call(button[key], buttonKey)) {
                                insidehtml += `${buttonKey}: ${button[key][buttonKey]};`;
                            }
                        }
                        insidehtml += '}';
                        insideArray.push(insidehtml);
                    }
                }
            }
            buttonHtml += '}';
            style.innerHTML += buttonHtml + insideArray.concat(' ');
        }

        if (anchor) {
            /* Anchor style */
            let anchorHtml = `.${id} .theme-anchor{ `;
            for (const key in anchor) {
                /**
                 * The body of a for-in should be wrapped in an if statement to filter unwanted properties from the prototype
                 */
                if (Object.prototype.hasOwnProperty.call(anchor, key)) {
                    anchorHtml += `${key}: ${anchor[key]};`;
                }
            }
            anchorHtml += '}';
            style.innerHTML += anchorHtml;
        }

        if (input) {
            /* Input style */
            let inputHtml = `.${id} .theme-input{ `;
            for (const key in input) {
                /**
                 * The body of a for-in should be wrapped in an if statement to filter unwanted properties from the prototype
                 */
                if (Object.prototype.hasOwnProperty.call(input, key)) {
                    inputHtml += `${key}: ${input[key]};`;
                }
            }
            inputHtml += '}';
            style.innerHTML += inputHtml;
        }
        /* Append in style tag */
        [style].map(el => {
            document.getElementsByTagName('head')[0].appendChild(el);
        });
    }

    setColors(colors: PorColors) {
        if (colors) {
            for (const key in colors) {
                if (Object.prototype.hasOwnProperty.call(colors, key)) {
                    const value: string | undefined = colors[key];
                    document.documentElement.style.setProperty(`${key}`, `${value}`);
                }
            }
        }
    }

    setStylingForSite(config: ConsumerLoginConfig) {
        this.setStyling(config.styling?.busyIndicator, 'por-busy-indicator');
        this.setStyling(config.styling?.changePassword, 'por-change-password');
        this.setStyling(config.styling?.forgotPassword, 'por-forgot-password');
        this.setStyling(config.styling?.signin, 'por-signin');
        this.setStyling(config.styling?.signup, 'por-signup');
        this.setStyling(config.styling?.verify, 'por-verify');
        /* eslint-disable @typescript-eslint/naming-convention */
        /**
         * Directly use in scss property
         */
        const themeColors: PorColors = {
            '--por-cp-primary': config.styling?.themeColors?.primaryColor || inputDefaults.styling?.themeColors?.primaryColor,
            '--por-cp-secondary': config.styling?.themeColors?.secondaryColor || inputDefaults.styling?.themeColors?.secondaryColor,
            '--por-color-primary': config.styling?.themeColors?.primaryColor || inputDefaults.styling?.themeColors?.primaryColor,
            '--por-color-secondary': config.styling?.themeColors?.secondaryColor || inputDefaults.styling?.themeColors?.secondaryColor
        };
        this.setColors(themeColors);
    }
}
